import React, { useEffect, useRef, useState } from "react"
import AccountLayout from "../../../components/accountLayout"
import UserInfo from "../../../components/userInfo"

const YourIdentity = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const formInputRef = useRef({})

  useEffect(() => {
    formInputRef.current = { ...formInputRef.current, formLevel: 1 }
  }, [])

  console.log("page level  >>>", formInputRef.current)
  return (
    <AccountLayout
      to="/account-details/status"
      formInput={formInputRef.current}
      title="Your info"
      isDisabled={isDisabled}
    >
      <form id="form-id">
        <UserInfo formInputRef={formInputRef} setIsDisabled={setIsDisabled} />
      </form>
    </AccountLayout>
  )
}

export default YourIdentity
